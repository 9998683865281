html {
    font-family: Chivo, sans-serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
input,
label,
button {
    font-family: Montserrat, sans-serif !important;
}

body {
    background-color: #150c25;
    color: #f2f8fa;
}

pre {
    display: block;
    font-size: 87.5%;
    color: inherit;
    background-color: #00000047;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

textarea,
input {
    font-family: monospace !important;
    background-color: #E9DEFF !important;
    border-radius: 6px;
    margin-bottom: 5px;
    width: 100%;
    height: 43px;
    margin-bottom: 14px;
    transition: all .125s ease-in-out;
    border: 2px solid #AF9CC6;
    color: #6410C7 !important;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
}

input:focus-visible {
    outline: none;
    border: 2px solid #dccfec;
}

.balanceInput:focus-visible {
    border: 2px solid #AF9CC6!important;
    border-right:0px!important;
}

.passwordTxtbox {
    font-size: 34px !important;
    color: #6410C7 !important;
}

.passwordTxtbox::placeholder {
    position: relative !important;
    top: -6px;
}

input::placeholder {
    font-size: 14px !important;
    color:#A97DE8!important;
    text-align: left;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#privateKey {
    text-align: center;
}

button {
    color: rgba(255, 255, 255, 0.65);
    border-radius: 15px;
    padding: 12px 25px 12px;
    background-color: #380c6a;
    border-style: solid;
    border-width: 1px;
    margin: 1px;
    border-color: #7300ff;
    text-align: center;
    font-size: 16px;
    outline: 0;
    transition: all .2s ease-in-out;
}

progress {
    color: #7300ff;
    border-radius: 6px;
    border: none;
}

progress[value]::-webkit-progress-value {
    background-image: -webkit-linear-gradient(top,
            rgba(255, 255, 255, 0.25),
            rgba(0, 0, 0, 0.25)),
        -webkit-linear-gradient(left,
            #380c6a,
            #7300ff);

    border-radius: 6px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

progress[value]::-moz-progress-bar {
    background-image:
        -moz-linear-gradient(top,
            rgba(255, 255, 255, 0.25),
            rgba(0, 0, 0, 0.25)),
        -moz-linear-gradient(left,
            #380c6a,
            #7300ff);

    border-radius: 6px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.hide-element {
    width: 0px;
    height: 0px;
    opacity: 0;
    display: none;
}

.fa-tiny-margin {
    margin-top: 4px;
}

.ptr {
    cursor: pointer;
    transition: all .175s ease-in-out;
}

.ptr:hover {
    opacity: 0.8;
}

.fa-stacked-ptr {
    cursor: pointer;
    margin-right: 15px;
}

.nav-logo {
    display: inline;
    vertical-align: middle;
    width: 150px;
}

.nav-link {
    font-weight: 500;
    font-size: 16px;
}

.navButton {
    color: #999;
}

.navButton:hover {
    color: #334758 !important;
}

.small-dark-bg {
    background: linear-gradient(#32135C 0 0) padding-box, linear-gradient(to top, #771DF3, #9631F4) border-box;
    border: 3px solid transparent;
    border-radius: 100px;
    display: flex;
}

.alert-danger {
    color: #fff;
    background-color: #ffabb361;
    border-color: #ba0014;
}

.nav-link {
    color: rgb(255, 255, 255, 85%) !important;
}

.nav-link:hover {
    color: rgba(255, 255, 255, 70%) !important;
}

.minor-notif-subtext {
    opacity: 0.5;
    padding: 10px 5px 30px;
    text-align: center;
}

.changelog {
    text-align: left;
}

.changelog p {
    margin-bottom: 0px;
    font-family: monospace !important;
    opacity: 0.75;
    color:#A49DB2;
    font-size: 15px;
}

.changelog b {
    color: #A77BFF;
}

.changelog p .dotSpan {
    padding-left: 7px;
    border-left-style: solid;
    border-left-width: 6px;
    border-left-color: #9221ff;
    height: 6px;
    display: inline-block;
    position: relative;
    top: -2px;
}

.changelog h3 {
    padding-left: 7px;
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: #9221ff;
    color:#E9DEFF;
    margin-bottom: 13px;
    font-size: 21px;
}

.noselect .reload {
    padding-left: 9px;
    font-size: 19px;
    cursor:pointer;
}

.large-box {
    background: rgba(43, 9, 80, 0.55);
    border-radius: 8px;
    border-color: #6222ab;
    border-width: 1px;
    border-style: solid;
    margin: 5px;
}

.large-box:hover {
    background: linear-gradient(217deg, rgb(51, 0, 153, 0.1), rgb(34, 0, 102, 0.3) 70.71%);
    border-color: #763acf;

}

#qrModal.modal {
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#qrReaderModal.modal {
    z-index: 1100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#qrReaderStream {
    width: 100%;
    height: 100%;
}

.scan-region-highlight-svg {
    stroke: #6800ff !important;
    filter: drop-shadow(0 0 3px rgb(122, 31, 255))
}

.modal {
    color: black;
}

.modal-label {
    position: relative;
    top: 20px;
    font-family: monospace;
    font-size: small;
}

.auto-fit {
    width: 100%;
    height: auto;
}

@keyframes disco {
    from {
        filter: hue-rotate(0deg);
    }

    to {
        filter: hue-rotate(360deg);
    }
}

.discoFilter {
    animation-name: disco;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: linear;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.innerShadow {
    -webkit-box-shadow: inset hsla(0, 0%, 100%, .25) 0 2px 2px, inset rgba(0, 0, 0, .6) 0 -1px 8px;
    box-shadow: inset 0 2px 2px hsla(0, 0%, 100%, .25), inset 0 -1px 8px rgba(0, 0, 0, .6);
}

.seed-phrase {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    background-color: #00000014;
    color: #8e21ff;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
}

.mono {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

/* a bootstrap override */
.modal-footer {
    display: flex!important;
    flex-wrap: nowrap;
    justify-content: center;
}

.centerFlex {
    display: flex !important;
    justify-content: center;
}

.placeholderCenter::placeholder {
    text-align: center!important;
}

.inline-address {
    font-family: mono;
    background-color: #00000014;
    color: #8e21ff;
    margin: 10px;
    padding: 3px;
    border-radius: 8px;
    display: inline;
}


#page-container {
    position: relative;
    min-height: calc(100vh - 75px);
}

#content-wrap {
    padding-bottom: 4rem;
    /* Footer height */
    margin-top: 75px;
}

@media (max-width: 768px) {

    #page-container {
        min-height: calc(100vh - 40px);
    }
}

.rm-pd {
    padding: 15px 15px 40px;
}

.max-width {
    width: 100%;
}

/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #080808;
    background-color: #000000;
}

/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    color: #ecd3af;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #334758 !important;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #9131ea !important;
}

.active {
    color: #9221FF !important;
}

/* Style the tab content */
.tabcontent {
    font-family: sans-serif;
    display: none;
    border-radius: 8px;
}

#Transaction.tabcontent {
    padding-bottom: 100px;
}


.frame {
    display: block;
    margin: 0 auto;
    background: #2B0950BF;
}

/* DASHBOARD */

#guiWallet {
    width: 100%;
}

#bcg-wallet-box {
    display: block;
    width: 100%;
    height: 87%;
    border: 1px #4b1282 solid;
    border-radius: 8px;
    box-shadow: 2px 2px 15px 2px #4b1271;
    margin: 0 0 280px 0;
    background: linear-gradient(0.25turn, rgba(77, 77, 77, 0.5), rgba(115, 0, 255, 0.3));
}

/* -- Headline dashboard -- */

#headline-balance {
    display: block;
    width: 100%;
    height: 150px;
}

#window-icon-piv {
    display: inline-block;
    padding: 30px 50px;
    height: 150px;
    width: auto;
}

#piv-ring {
    display: inline-block;
    width: 65px;
    height: 65px;
    border-style: solid;
    border-radius: 50%;
    border-width: 3px;
    background-size: cover;
    background-image: url(../logo-circle.svg);
}

#number-piv {
    display: inline-block;
    vertical-align: top;
    width: auto;
    height: 60px;
    padding: 15px 10px 15px 60px;
}

#guiBalanceBox {
    display: inline-block;
    font-size: x-large;
}

#balance-box {
    display: block !important;
    margin: 20px auto 0 auto !important;
    width: 95%;
    height: 125px;
    background: linear-gradient(0.25turn, rgba(122, 122, 122, 0.5), rgba(115, 0, 255, 0.4));
}

.balance-title {
    height: 35px;
}

#bal-title {
    float: right;
    padding: 45px 50px;
}

/* -- Headline -- */

/* -- Background -- */

#big-frame-address {
    display: block;
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding-top: 40px;
}

#address-box {
    height: 380px;
    padding: 30px 25px;
}

#address-box.large-box {
    width: 96% !important;
    margin: 0 auto;
    background: linear-gradient(0.15turn, rgba(43, 9, 80, 0.45), rgba(43, 9, 80, 0.02));
}

/* -- Background -- */

/* -- Box of Content -- */

#title-address-box {
    width: 30%;
    padding: 10px 20px 0 30px;
    margin: 0 !important;
}

#box-info-address {
    display: inline-block;
    width: 69%;
    padding-top: 40px;
}

/* -- Into -- */

#address-info {
    display: inline-block;
    width: 75%;
    padding: 0 0 20px 40px;
}

#guiAddress {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    line-break: anywhere;
    word-break: break-all;
    font-family: monospace;
    font-weight: 300;
}

#guiQRButton {
    display: inline-block;
    width: auto;
    padding: 10px 0;
    font-size: large;
}

/* -- Into -- */

#ring {
    display: inline-block;
    padding: 10px 10px 20px 50px;
}

#identicon {
    background-color: white;
    border-color: white;
    border-width: 3px;
    border-style: solid;
    border-radius: 1000px;
}

/* -- Box of Content -- */

/* -- Pubkey QR -- */

.mobile-pubkey {
    display: none !important;
}

#box-info-address {
    vertical-align: top;
}

#pubkey-padd {
    display: inline-block;
    height: auto;
    width: 30%;
}

#pubkey-box.col-md-4 {
    float: none;
    clear: both;
    display: block !important;
    max-width: 100%;
    padding: 0 0 20px 0;
}

#pubkey-box {
    display: block;
    width: 100% !important;
    margin: 0;
    text-align: center;
}

#PrivateCipheredQR img,
#PrivateQR img,
#PublicQR img {
    width: 95px !important;
    height: 95px !important;
}

#PrivateCipheredTxt,
#PrivateTxt {
    color: black;
    font-size: 12px !important;
    text-align: center;
    line-height: 20px;
}


/* -- Pubkey QR -- */
.margin-padded-qr {
    margin-bottom: 10px;
    padding: 5px 10px 10px 10px;
}

/* STAKE */

.add-frame {
    display: block;
    margin: 0 auto;
    width: 85% !important;
    height: 800px !important;
    ;
    background: rgba(72, 2, 125, 0.60);
    border: 1px solid #a366ff;
    border-radius: 8px;
    padding: 5px;
}

#stake-title {
    display: inline-block;
    margin: 0;
    cursor: default;
}

#StakingTab {
    padding: 10px 10px 30px;
    margin: 20px 0 100px;
}

.staking-banner-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: 200px;
    width: 100%;
}

.staking-rewards-header {
    height: 15%;
    font-size: large;
    overflow: hidden;

}

.staking-rewards-list {
    overflow: auto;
    font-size: large;
    height: 85%;
}

#staking-rectangle {
    position: relative;
    display: block;
    height: 100px;
    max-width: 100% !important;
    border-bottom: 1px solid #a366ff;
}

#pivx-price-box {
    height: 100px;
    width: 240px;
    float: left;
}

#icon-box-piv {
    display: inline-block;
    width: 80px;
    height: 80px;
    padding: 20px 15px 40px 35px;
    margin: 0 !important;
}

#piv-price-amount {
    margin: 0 !important;
    padding: 30px 30px 30px 30px;
    float: right;
}

#guiBalanceBoxStaking {
    display: inline-block;
    margin: 0;
    font-size: x-large;
}

#staking-box {
    height: 100px;
    width: 220px;
    float: right !important;
    padding: 30px 30px 30px 20px;
}

.staking-piv-icon {
    display: inline-block;
    width: 55px;
    height: 55px;
    border-style: solid;
    border-radius: 1000px;
    border-width: 3px;
    background-size: cover;
    background-image: url(../logo-circle.svg);
}

.stake-box {
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 220px;
    margin: 30px 5px 10px;
}


.stake-balances {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    padding: 5px;
}

.stake-input {
    color: black;
    font-size: 16px !important;
    text-align: center;
    line-height: 20px;
}


/* FOOTER */

#foot {
    display: block;
    width: 100%;
    padding: 10px;
}

.footer {
    width: 100%;
    color: white;
    padding: 0;
}

.footer a {
    color: white;
    font-weight: bold;
}

#dcfooter {
    display: inline;
}

.navbarSpecial {
    background: linear-gradient(90deg, #1a122d, #211138);
    box-shadow: 0 0px 15px 3px #0e071a;
    position: fixed;
    width: 100%;
    min-height: 77px;
}

/* New styling */

/* Buttons */
.pivx-button-small {
    display: inline-block;
    outline: none;
    margin: 5px;
    font-family: Montserrat, sans-serif;
    height: auto;
    padding: 8px 18px;
    background-image: linear-gradient(183deg, #7B1FD8, #6D1ED8);
    position: relative;
    z-index: 1;
    line-height: 1.25em;
    font: 13px Montserrat, sans-serif !important;
    letter-spacing: .025em;
    font-family: Montserrat, sans-serif;
    color: #fffeff;
    text-transform: uppercase;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
    -webkit-box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -moz-box-shadow: 3px 3px 25px 2px rgba(26, 14, 42, .5);
    box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    padding-top: 6px;
}

.pivx-button-big {
    display: inline-block;
    outline: none;
    margin: 5px;
    font-family: Montserrat, sans-serif;
    height: auto;
    padding: 14px 20px;
    background-image: linear-gradient(183deg, #7B1FD8, #6D1ED8);
    position: relative;
    z-index: 1;
    line-height: 1.25em;
    font: 15px Montserrat, sans-serif !important;
    letter-spacing: .025em;
    font-family: Montserrat, sans-serif;
    color: #fffeff;
    text-transform: uppercase;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
    -webkit-box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -moz-box-shadow: 3px 3px 25px 2px rgba(26, 14, 42, .5);
    box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}



.pivx-button-big-cancel,
.pivx-button-small-cancel {
    display: inline-block;
    outline: none;
    margin: 5px;
    font-family: Montserrat, sans-serif;
    background: linear-gradient(#32135C 0 0) padding-box, linear-gradient(to top, #771DF3, #9631F4) border-box;
    height: auto;
    padding: 13px 20px;
    position: relative;
    z-index: 1;
    line-height: 1.25em;
    font: 15px Montserrat, sans-serif !important;
    letter-spacing: .025em;
    font-family: Montserrat, sans-serif;
    color: #fffeff;
    text-transform: uppercase;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    border:2px solid transparent;
    border-radius: 35px;
    -webkit-box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -moz-box-shadow: 3px 3px 25px 2px rgba(26, 14, 42, .5);
    box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.pivx-button-small-cancel {
    font: 13px Montserrat, sans-serif !important;
    padding: 8px 18px;
    padding-top: 6px;
}

.pivx-button-big-no-gov {
    background-image: linear-gradient(183deg, #B31010, #A10E0E);
    border-color: #850C0C;
    pointer-events: none;
}

.pivx-button-big-yes-gov {
    background-image: linear-gradient(183deg, #27823F, #237639);
    border-color: #1F6130;
    pointer-events: none;
}

.pivx-button-big::before {
    position: absolute;
    content: "";
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(183deg, #8e1fef, #741cef);
    z-index: -1;
    transition: all .2s ease-in-out;
    opacity: 0;
}

.pivx-button-medium {
    display: inline-block;
    outline: none;
    margin: 5px;
    font-family: Montserrat, sans-serif;
    height: auto;
    padding: 8px 16px;
    background-image: linear-gradient(183deg, 7B1FD8, #6D1ED8);
    position: relative;
    z-index: 1;
    line-height: 1.25em;
    font: 13px Montserrat, sans-serif !important;
    letter-spacing: .025em;
    font-family: Montserrat, sans-serif;
    color: #fffeff;
    text-transform: uppercase;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
    -webkit-box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -moz-box-shadow: 3px 3px 25px 2px rgba(26, 14, 42, .5);
    box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.pivx-button-medium::hover {
    position: absolute;
    content: "";
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(183deg, #8e1fef, #741cef);
    z-index: -1;
    transition: all .2s ease-in-out;
    opacity: 0;
}

.pivx-button-big:hover::before {
    opacity: 1;
}

.pivx-button-outline {
    font: 15px Montserrat, sans-serif !important;
    background-image: linear-gradient(183deg, #9131EA, #731EEA);
    padding: 3px;
    display: flex;
    border-radius:100px;
    -webkit-box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -moz-box-shadow: 3px 3px 25px 2px rgba(26, 14, 42, .5);
    box-shadow: 3px 3px 25px 2px rgb(26 14 42 / 50%);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    text-transform: uppercase;
    cursor:pointer;
}

.pivx-button-outline span {
    background: #37195F;
    border-radius:100px;
    color: #fffeff;
    padding: 14px 20px;
    transition: all .125s ease-in-out;
}

.pivx-button-outline span:hover {
    background-color:#2f1452;
}

.pivx-button-outline-small {
    font: 13px Montserrat, sans-serif !important;
}

.pivx-button-outline-small span {
    padding: 7px 16px;
}

.button-padd {
    display: inline-block;
    height: auto;
    width: auto;
    padding: 15px 10px;
}

.home-hero {
    /*background: radial-gradient(45vw 80% at 90% 40%, rgba(162, 50, 255, .3), rgba(44, 11, 76, 0) 95%), linear-gradient(90deg, rgba(44, 11, 76, .9), rgba(80, 15, 131, .5) 60%, rgba(44, 11, 76, .9)), linear-gradient(35deg, rgba(44, 11, 76, .3), rgba(80, 15, 131, .3) 37%, rgba(44, 11, 76, .3) 0, rgba(80, 15, 131, .3) 76%, rgba(44, 11, 76, .3) 0, rgba(80, 15, 131, .3)), linear-gradient(90deg, #2c0b4c, #500f83 60%, #2c0b4c);*/
    background: linear-gradient(135deg, #271a40 0%, #1b0e2e 15%, #1a122d 15%, #201431 37%, #1a1529 37%, #221237 59%, #1d112d 59%, #1d1030 81%, #181225 81%, #281c41 100%);
    background-attachment: fixed;
}

.home-hero-dark {
    background: linear-gradient(135deg, #1D1231 0%, #050308 15%, #0E081A 15%, #0F0A1C 37%, #120D20 37%, #0B0914 59%, #100C1E 59%, #0C0717 81%, #120920 81%, #120920 100%);
    background-attachment: fixed;
}

.title-section {
    margin-bottom: 20px;
}

/*PIVX WARNINGS*/
.warning-message {
    display: none;
    background-color: #9021fe;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.warning-message p {
    margin-bottom: 0px;
}

.warning-message a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    background-color: transparent;
}

.alert-danger {
    color: #fff;
    background-color: #4b1282;
    border-color: #8300ff;
}

/*PIVX TEXT CUSTOMS*/
.pivx-bold-title {
    line-height: 1.35em;
    font-size: calc(37.88235px + .70588vw);
    font-weight: 700;
    font-family: Montserrat, sans-serif;
}

.pivx-bold-title-smaller {
    line-height: 1.35em;
    font-size: 25px;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
}

.pivx-bold-title span,
.pivx-bold-title-smaller span {
    font-size: 26px;
    display: block;
    line-height: 1.2em;
    font-weight: 300;
    color:#E9DEFF;
}

.pivx-bold-title div {
    color:#E9DEFF;
}

.center-text {
    text-align: center;
}

/*PIVX FEATURES SECTION*/
.pivx-feature-father {
    margin-top: 90px;
}

.pivx-feature-interior {
    background: linear-gradient(90deg, #2b1542, #230f3c);
    padding: 25px;
    text-align: center;
    border-radius: 10px;
}

.pivx-feature-interior h4 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 19px;
}

.pivx-feature-interior h5 {
    opacity: 0.65;
    padding: .7rem 0 1.25rem;
    line-height: 1.75em;
    font-size: 16px;
    font-weight: 300 !important;
}

.feature-icon {
    text-align: center;
    width: 100% !important;
    margin-top: -90px;
    min-height: 150px;
}

.feature-icon img {
    width: 146px;
    height: auto;
}

.smaller-feature {
    width: 100px !important;
}

.features-section {
    margin-top: 0px;
}

.buttoni-icon {
    vertical-align: top;
    display: inline-block;
    padding: 0 4px 0 3px;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    width: 29px;
}

.iconList svg,
.publicSwitchIcon svg {
    fill:#9221FF!important;
}

.iconList svg {
    position: relative;
    top: 3px!important;
    height:17px!important;
}

.iconList {
    padding: 0px !important;
    width: 17px;
}

.buttoni-icon svg {
    display: block;
    width: auto;
    height: 24px;
    max-height: 24px;
    margin: 0;
    fill: #fffeff;
}

.dcWallet-topLeftIcons svg {
    display: block;
    width: auto;
    height: 20px;
    max-height: 24px;
    margin: 0;
    fill: #9621FF;
    top: 8px;
    position: relative;
}

.buttonj-icon svg {
    display: block;
    width: auto;
    height: 24px;
    max-height: 24px;
    margin: 0;
    fill: #fffeff;
    position: relative;
    left: 3px;
}

.checkIcon svg {
    display: block;
    width: auto;
    height: 11px;
    max-height: 11px;
    margin: 0;
    fill: #fffeff;
}

.shieldBalanceLogo svg {
    display: block;
    width: auto;
    height: 16px;
    max-height: 16px;
    margin: 0;
    fill: #9221FF;
    position: relative;
    top: 3px;
}

.hourglassImmatureIcon svg {
    display: block;
    width: auto;
    height: 16px;
    max-height: 16px;
    margin: 0;
    fill: #7c659e;
}

.immatureBalanceSpan {
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7c659e;
    font-weight: 400;
    padding-top: 13px;
    padding-bottom: 13px;
}

.logo-pivBal {
    display: inline-block;
    position: relative;
    left: -6px;
}

.logo-pivBal svg {
    display: block;
    width: auto;
    height: 27px;
    max-height: 27px;
    margin: 0;
    fill: #fffeff;
}

.buttoni-text {
    vertical-align: top;
    display: inline-block;
    padding: 1px 0 0;
}

.buttoni-arrow {
    vertical-align: top !important;
    display: inline-block;
    padding: 4px 0 4px 10px;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.buttoni-arrow svg {
    display: block;
    width: 15px;
    height: auto;
    margin: 0;
    fill: #fffeff;
}

.purple-icon-link {
    color: #9621ff;
}

.purple-icon-link,
.white-icon-link {
    padding: 0;
    font-weight: 500;
    text-transform: uppercase;
}

.black-link .link-icon.link-icon-suffix,
.dark-link .link-icon.link-icon-suffix,
.faded-link .link-icon.link-icon-suffix,
.gray-link .link-icon.link-icon-suffix,
.light-link .link-icon.link-icon-suffix,
.pink-link .link-icon.link-icon-suffix,
.purple-icon-link .link-icon.link-icon-suffix,
.purple-link .link-icon.link-icon-suffix,
.shaded-link .link-icon.link-icon-suffix,
.white-icon-link .link-icon.link-icon-suffix,
.white-link .link-icon.link-icon-suffix,
a .link-icon.link-icon-suffix {
    padding: 0 0 0 .6rem;
    float: right;
    margin-top: 2px;
    -webkit-transition: padding-left .14s ease-out;
    -moz-transition: padding-left .14s ease-out;
    -o-transition: padding-left .14s ease-out;
    transition: padding-left .14s ease-out;
}

.black-link .link-icon svg,
.dark-link .link-icon svg,
.faded-link .link-icon svg,
.gray-link .link-icon svg,
.light-link .link-icon svg,
.pink-link .link-icon svg,
.purple-icon-link .link-icon svg,
.purple-link .link-icon svg,
.shaded-link .link-icon svg,
.white-icon-link .link-icon svg,
.white-link .link-icon svg,
a .link-icon svg {
    display: block;
    width: 19px;
    height: auto;
    fill: #cac9d2;
}

.black-link,
.dark-link,
.faded-link,
.gray-link,
.light-link,
.pink-link,
.purple-icon-link,
.purple-link,
.shaded-link,
.white-icon-link,
.white-link,
a {
    vertical-align: top;
    display: inline-block;
    text-decoration: none !important;
}

.black-link,
.dark-link,
.faded-link,
.gray-link,
.light-link,
.pink-link,
.purple-icon-link,
.purple-link,
.shaded-link,
.white-icon-link,
.white-link,
a {
    outline: none;
    background: none;
    border: none;
}

/*PIVX DASHBOARD*/
.dashboard-item {
    background: rgba(43, 9, 80, .38);
    border-radius: 12px;
    padding: 23px;
    text-align: center;
    float: left;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid #3c1170;
    transition: all .125s ease-in-out;
    height:100%;
    cursor:pointer;
}


.dashboard-item:hover {
    border: 2px solid #9221ff;
}

.send-eligibility-section .container .column-padded-around .coinstat-icon,
.supply-control-section .container .column-padded-around .coinstat-icon {
    margin-bottom: .5rem;
}

.coinstat-icon,
.coinstat-icon svg,
.masternodes-requirements-section .masternode-requirement-icon,
.masternodes-requirements-section .masternode-requirement-icon svg,
.setup-icon,
.setup-icon svg {
    margin: 0 auto;
}

.coinstat-icon,
.feature-icon,
.masternodes-requirements-section .masternode-requirement-icon,
.setup-icon {
    display: block;
    width: 75px;
    height: 75px;
    padding: 0;
}

.coinstat-icon svg,
.feature-icon svg,
.masternodes-requirements-section .masternode-requirement-icon svg,
.setup-icon svg {
    display: block;
    width: 75px;
    max-height: 75px;
    padding: 0;
    fill: #9621ff;
}

.dashboard-title {
    margin-top: 15px;
}

.dashboard-title p {
    font-size: 0.85rem;
}

.badge {
    margin-bottom: 13px;
}

.private-key-area {
    height: 29px !important;
    font-size: 14px !important;
    padding-top: 4px;
    padding-bottom: 4px;
    background: #521685;
    border: none;
    resize: none;
    color: white;
}

.export-key {
    width: 100%;
    padding-bottom: 36px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

/* SEND */

#sendNotice {
    margin: 10px 0 20px 0;
}

#communication {
    color: white;
    opacity: 0.6;
    padding: 15px 5px 70px 5px;
}

#signed-raw {
    padding: 10px 0 5px;
}

.bold-trans {
    padding: 15px 0 15px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

/* SETTINGS */

#submit-button.pivx-button-big {
    width: auto !important;
    padding: 14px 90px;
}


/* BREAKPOINTS */

@media screen and (max-width: 1200px) {

    /* Stake page */

    .button-padd .pivx-button-big {
        margin: 0;
        display: inline-block !important;
        width: 100% !important;
        padding: 15px 13px;
    }
}

@media screen and (max-width: 1199px) {

    /* Dashboard page */
    #genKeyWarning .pivx-button-big {
        margin-top: 20px;
    }

    #PrivateCipheredQR img,
    #PrivateQR img,
    #PublicQR img {
        width: 90px !important;
        height: 90px !important;
    }

    #box-info-address {
        width: 64%;
    }

    #address-info {
        width: 60%;
    }

    #pubkey-padd {
        width: 33%;
    }
}

@media screen and (min-width: 1025px) {

    /*Main Wallet page */
    #PrivateTxt {
        font-size: 25px;
    }

    #PrivateCipheredTxt {
        font-size: 25px;
    }

    #PublicTxt {
        font-size: 25px;
    }
}

@media screen and (max-width: 1024px) {

    /*Main Wallet page */
    #PrivateTxt {
        font-size: 18px;
    }

    #PrivateCipheredTxt {
        font-size: 18px;
    }

    #PublicTxt {
        font-size: 25px;
    }

    /* Settings */

    #Settings .pivx-button-big {
        margin: 8px 2px;
    }
}

@media screen and (max-width: 1000px) {

    /* Stake page */

    #guiBalanceBoxStaking {
        display: inline-block;
        width: 100%;
    }

    .stake-box.large-box.col-md-4 {
        padding: 10px;
        display: inline-block !important;
        width: 50% !important;
        margin: 30px 10px !important;
    }

    .add-frame {
        width: 100% !important;
    }

    .stake-box .button-padd {
        padding: 15px 6px;
    }

    .stake-box .button-padd .pivx-button-big {
        padding: 15px 22px 15px 27px;
    }

    .stake-box .button-padd .buttoni-icon {
        display: none;
    }

    .button-padd .buttoni-arrow {
        padding-left: 5px;
        padding-right: 0;
    }
}

@media screen and (max-width: 992px) {

    /* Dashboard page */

    #big-frame-address {
        padding-top: 20px;
    }

    #genKeyWarning div {
        max-width: 100% !important;
    }

    #genKeyWarning p {
        width: 100% !important;
        padding-bottom: 15px;
    }

    #genKeyWarning .pivx-button-big {
        float: none;
        clear: both;
        display: block !important;
        margin: 0 auto;
    }

    #bcg-wallet-box {
        height: 98%;
    }

    #address-box.large-box {
        height: 260px;
        background: none;
    }

    #box-info-address {
        display: block;
        width: 100%;
        padding-top: 20px;
    }

    #address-info {
        width: 69%;
    }

    .margin-padded-qr {
        margin-bottom: 10px;
        padding: 10px;
    }

    #pubkey-padd {
        width: auto;
        display: block;
        padding-top: 60px;
    }

    #pubkey-box {
        display: block;
    }

    #pubkey-box .pivx-button-big {
        width: 230px !important;
    }

    #PrivateCipheredQR img,
    #PrivateQR img,
    #PublicQR img {
        width: 75px !important;
        height: 75px !important;
    }

    /* Intro page */
    .intro-page .col-md-11 {
        width: 100% !important;
        padding-bottom: 35px;
    }
}

@media screen and (max-width: 770px) {

    #pivx-price-box {
        width: 200px;
    }

    #piv-price-amount {
        padding: 30px 15px 30px 35px;
    }

    #icon-box-piv {
        padding: 20px 10px 30px 15px;
        margin: 0 !important;
    }

    #staking-box {
        width: 190px;
        padding: 30px 5px 30px 20px;
    }
}

@media screen and (max-width: 768px) {

    /* Main Wallet page */
    #PrivateTxt {
        font-size: 17px;
    }

    #PrivateCipheredTxt {
        font-size: 17px;
    }

    #PublicTxt {
        font-size: 25px;
    }

    /* Paddings - text */
    .dashboard-item {
        padding-left: 5%;
        padding-right: 5%;
    }

    /* Dashboard page */

    #balance-box {
        width: 92%;
        height: 120px;
    }

    #guiBalanceBox {
        margin: 0;
    }

    #address-box.large-box {
        height: 240px;
        background: none;
    }

    #address-info {
        width: 65%;
    }

    #pubkey-box {
        padding-top: 40px;
    }

    #pubkey-box .pivx-button-big {
        width: 225px !important;
    }
}

@media screen and (max-width: 767px) {

    /* Tables */
    table td {
        max-width: 130px;
    }

    table td a {
        width: 100%;
    }

    table td a code {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        min-width: 30px
    }

    /* Dashboard */

    #genKeyWarning .pivx-button-big {
        padding: 13px 20px;
    }

    #piv-ring {
        display: none;
    }

    #window-icon-piv {
        padding: 30px 0 30px 30px;
    }

    #ring {
        padding: 0 5px 20px 50px;
    }

    #identicon {
        height: 60px;
        width: 60px;
    }

    /* Stake page */

    .add-frame {
        height: 1000px !important;
    }

    .stake-box.large-box.col-md-4 {
        padding: 10px 20px;
        width: 85% !important;
    }

    .modal-dialog {
        max-width: 100%;
        margin: 0;
    }

    .modal-content {
        /*height: 100%;*/
    }

    .modal-body {
        overflow-y: auto;
    }
}

@media screen and (max-width: 600px) {


    /* Buttons - Hide icons or it will be deformed */
    .buttoni-arrow,
    .buttoni-icon {
        /*display: none;*/
    }

    /* Main Wallet page */
    #PrivateTxt {
        font-size: 13px;
    }

    #PrivateCipheredTxt {
        font-size: 13px;
    }

    #PublicTxt {
        font-size: 17px;
    }

    /* STAKE page */

    #staking-box {
        padding: 30px 5px 30px 35px;
    }

    #stake-title {
        font-size: 25px;
    }

    #submit-button.pivx-button-big {
        padding: 14px 85px 14px 100px;
        margin-top: 20px;
    }

    /* Dashboard */

    #address-info {
        width: 55%;
    }
}

@media screen and (max-width: 550px) {

    /* Dashboard page */

    #bcg-wallet-box {
        height: 100%;
    }

    #ring {
        padding: 5px 5px 30px 40px;
        vertical-align: top;
    }

    #bal-title {
        padding: 45px 40px 45px 20px;
    }

    #window-icon-piv {
        padding: 30px 0;
    }

    #address-box.large-box {
        padding-top: 20px;
        height: 250px;
        /*background: linear-gradient(217deg, rgb(51, 0, 153, 0.1), rgb(34, 0, 102, 0.3) 70.71%);*/
        border-color: #763acf;
    }

    #title-address-box {
        width: auto;
        padding: 10px 20px 0 20px;
    }

    #box-info-address {
        padding-top: 25px;
    }

    #address-info {
        width: 59%;
    }

    /* STAKE page */

    #info {
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 535px) {

    /* Dashboard */

    #pubkey-box .pivx-button-big {
        width: 200px !important;
    }
}

@media screen and (max-width: 510px) {

    /* Dashboard page */

    #pubkey-box .pivx-button-big {
        padding: 12px 10px;
    }

    #icon-box-piv {
        display: none;
    }

    #pivx-price-box {
        width: 130px;
    }
}

@media screen and (max-width: 480px) {

    /* Main Wallet page */
    #PrivateTxt {
        font-size: 8px;
    }

    #PrivateCipheredTxt {
        font-size: 8px;
    }

    #PublicTxt {
        font-size: 13px;
    }

    /* Stake */


    #pivx-price-box {
        width: auto;
    }

    /* Dashboard */

    #number-piv {
        padding: 15px 5px 15px 40px;
    }

    #ring {
        padding: 5px 5px 30px 30px;
    }

    #pubkey-padd {
        padding-top: 40px;
    }
}

@media screen and (max-width: 420px) {

    /* Send */

    .max-width {
        width: 98%;
    }

    #Transaction.tabcontent {
        padding: 10px 15px 110px 15px;
    }

    /* Settings page */

    #submit-button.pivx-button-big {
        display: block;
        height: auto;
        margin: 0 auto;
    }

    .buttoni-arrow {
        display: none;
    }

    #navbarToggler {
        position: sticky;
        left: 290px;
    }

    /* Dashboard */


    #piv-ring {
        display: none !important;
    }

    #guiBalanceBox {
        font-size: 18px !important;
    }

    #balance-box h3 {
        font-size: 20px;
    }

    #identicon {
        width: 55px;
        height: 55px;
    }

    #pubkey-box .pivx-button-big {
        padding: 11px 10px;
    }

    #pubkey-box.col-md-4 {
        padding-top: 20px;
    }
}

@media screen and (max-width: 393px) {

    /* Dashboard */

    #piv-price-amount {
        padding: 30px 15px;
    }

    #address-box.large-box {
        padding-top: 15px;
        height: 255px;
    }

    #title-address-box {
        padding: 18px 20px 0 15px;
    }

    #ring {
        padding: 5px 5px 30px 20px;
    }

    #address-info {
        padding-left: 15px;
    }

    #pubkey-padd {
        padding-top: 30px;
    }
}

@media screen and (max-width: 380px) {

    /* Nav */
    #navbarToggler {
        left: 180px;
    }

    /* Stake */

    #staking-box {
        padding: 30px 10px 35px 10px;
        height: auto;
        width: auto;
    }

    /* Dashboard */

    #number-piv {
        padding: 15px 5px 15px 35px;
    }

    #bal-title {
        padding: 45px 30px 45px 10px;
    }

    #balance-box h3 {
        font-size: 24px;
    }

    #pubkey-box .pivx-button-big {
        padding: 11px 10px;
    }
}

@media screen and (max-width: 360px) {

    /* Dashboard */

    #address-info {
        width: 90%;
        padding-left: 35px;
    }

    #guiQRButton {
        padding: 15px 10px;
    }

    #piv-price-amount {
        padding: 30px 5px 30px 20px;
    }

    #bal-title {
        padding: 47px 25px 47px 10px;
    }

    #balance-box h3 {
        font-size: 22px;
    }

    #pubkey-box .pivx-button-big {
        width: 150px !important;
    }

    /* Stake */

    #staking-box {
        padding: 35px 10px 35px 10px;
    }

    #stake-title {
        font-size: 22px !important;
        height: auto;
    }
}

@media screen and (max-width: 332px) {

    /* Dashboard */

    #balance-box {
        width: 92%;
        height: 140px;
    }

    #window-icon-piv {
        padding: 5px 10px;
        height: auto;
    }

    #bal-title {
        float: none;
        clear: both;
        padding: 10px 10px 10px 50px;
    }
}

@media screen and (max-width: 320px) {

    /* Intro */

    #navbarToggler {
        font-size: 14px;
    }

    .feature-icon img {
        width: 115px !important;
        height: auto;
    }

    .feature-icon .smaller-feature {
        width: 80px !important;
        height: auto;
    }

    /* Stake */

    #piv-price-amount {
        padding: 30px 5px 30px 10px;
    }

    #staking-box {
        padding: 35px 5px 35px 5px;
    }

    /* Settings */

    .pivx-button-big {
        font-size: 14px !important;
    }

    #submit-button.pivx-button-big {
        padding: 14px 50px !important;
    }
}

@media screen and (max-width: 300px) {

    /* Intro */

    #navbarToggler {
        left: 80px !important;
        font-size: 12px;
    }

    #page-container {
        min-width: 280px;
        min-height: 900px;
    }

    .container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row {
        margin-right: 1px;
    }

    .features-section .col-md-6 {
        float: none !important;
        clear: both !important;
        display: block !important;
        margin: 100px auto 50px auto;
        width: 220px;
        margin-right: 20px;
    }

    .pivx-feature-interior {
        width: 100% !important;
        padding: 15px 8px;
    }

    .pivx-bold-title {
        font-size: calc(32px + 1vw);
    }

    /* Stake */

    .add-frame {
        height: 750px !important;
    }

    .stake-box.large-box.col-md-4 {
        margin: 30px 10px 10px 10px !important;
    }

    #piv-price-amount {
        padding: 30px 60px;
        text-align: center !important;
    }

    #staking-box {
        padding: 30px 20px;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid #a366ff;
    }

    #stake-title,
    #guiBalanceBoxStaking {
        font-weight: 600;
    }

    .stake-box .button-padd .pivx-button-big {
        padding: 14px 18px;
    }
}

/* Alert */
.alertPositioning {
    position: fixed;
    z-index: 10000;
    right: 15px;
    top: 100px;
    display: flex;
    flex-direction: column;
}

.alertpop {
    padding: 20px;
    right: 0px;
    background-color: #282828b5;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    margin-bottom: 15px;
    border-radius: 5px;
}

.alertpop.success {
    background-color: #0DE69694;
}

.alertpop.info {
    background-color: #2196F37D;
}

.alertpop.warning {
    background-color: #d94a2982;
}

.alertpop:hover {
    cursor: pointer;
}

.height-100 {
    height: 100%;
}

.dashboard-display {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

.textbox-opacity-trans {
    opacity: 0;
}

.bounce-ani {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-20px);
    }

    60% {
        -webkit-transform: translateY(-10px);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

.no-antialias {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}

.wallet-code {
    background-color: rgba(0, 0, 0, 14%);
    padding: 6px 9px;
    border-radius: 5px;
    margin-bottom: 1px;
    display: block;
    width: 100%;
}

.exportPrivateKey {
    font-weight: bold;
}

.pubPrivKey {
    background-color: rgba(0, 0, 0, 25%);
    padding: 8px 12px;
    margin-top: 4px;
    display: block;
    border-radius: 6px;
    color: #ede3f7;
    font-weight: 900;
}



.newBtn-red-outline {
    padding: 6px 10px;
    border: 2px solid #FFDEDE;
    border-radius: 8px;
    color: #FF8071;
    font-size: 14px;
    background-color: #fff;
}

.newBtn-red-outline:hover {
    border: 2px solid #FFDEDE;
    color: #FF8071;
    background-color: #fff7f6;
}

.newBtn-red-outline:focus {
    border: 2px solid #f9bbbb;
    box-shadow: 0 0 0 0.2rem #f9bbbb40;
}

.newBtn-purple-outline {
    padding: 6px 10px;
    border: 2px solid #D3BCF1;
    background-color: #fff;
    border-radius: 8px;
    color: #AD78F3;
    font-size: 14px;
}

.newBtn-purple-outline:hover {
    border: 2px solid #D3BCF1;
    color: #AD78F3;
    background-color: #f1e9fb;
    box-shadow: none;
}

.newBtn-purple-outline:focus {
    border: 2px solid #cba7fa;
    box-shadow: 0 0 0 0.2rem #cba7fa40;
}

.navbar-nav .nav-item span {
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
    transition: all .2s ease-in-out;
    text-transform: uppercase;
}



.modal-content .modal-header,
.modal-content .modal-footer {
    border: 0px;
}



.dcWallet-balances {
    max-width: 310px;
    background-color: #301f4c61;
    border: 2px solid #31135A;
    border-radius: 10px;
    padding: 3px 0px 0px 0px;
    backdrop-filter: blur(4px);
}

.dcWallet-balances .lessBot {
    margin-bottom: -20px;
}

.dcWallet-balances .lessTop {
    margin-top: -28px;
}

.dcWallet-balances .dcWallet-pivxBalance {
    font-size: 38px;
}

.dcWallet-balances .dcWallet-pivxTicker {
    font-size: 17px;
}

.dcWallet-balances .dcWallet-usdBalance {
    display: block;
    margin-top: -9px;
}

.dcWallet-balances .dcWallet-usdValue {
    font-size: 13px;
    color: rgb(206, 206, 206);
}

.dcWallet-activityLbl {
    font-size: 20px;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}

.dcWallet-activity {
    max-width: 95svw;
    padding: 14px 16px;
    background-color: #301f4c61;
    border: 2px solid #31135A;
    border-radius: 10px;
    backdrop-filter: blur(4px);
}

.dcWallet-svgIconPurple {
    display: inline-block;
    margin-top: -20px;
    position: relative;
    top: 6px;
}

.dcWallet-svgIconPurple svg {
    display: block;
    width: auto;
    height: 21px;
    max-height: 21px;
    margin: 0;
    fill: #f2e2ff;
}

.dcWallet-warningMessage {
    cursor: pointer;
    text-align: left;
    max-width: 310px;
    margin-bottom: 15px;
    padding: 8px 12px;
    display: flex;
    line-height: 18px;
    background: linear-gradient(#32135C 0 0) padding-box, linear-gradient(to top, #771DF3, #9631F4) border-box;
    border: 3px solid transparent;
    border-radius: 100px;
}

.dcWallet-warningMessage-dark {
    background: linear-gradient(#221239 0 0) padding-box, linear-gradient(to top, #6e1ed8, #7c1fd8) border-box!important;
}

.dcWallet-warningMessage .messLogo {
    padding-top: 1px;
    padding-right: 12px;
    position: relative;
    left: 10px;
    top: 5px;
    margin-right: 4px;
}

.dcWallet-warningMessage .messMessage {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.dcWallet-warningMessage .messMessage .messTop {
    font-size: 11px;
    color: #ffffffbd;
}

.dcWallet-warningMessage .messMessage .messBot {
    font-weight: 500;
    font-size: 15px;
}

.dcWallet-topRightMenu {
    font-size: 18px;
    cursor: pointer;
    width: 27px;
    transition: all .2s ease-in-out;
}

.dcWallet-topRightMenu .btn-group {
    margin-top: 8px;
    margin-right: 6px;
}

.dcWallet-topRightMenu:hover {
    color: #dcdcdc;
}

.dcWallet-topRightMenu .dropdown-move {
    transform: translate3d(12px, 26px, 0px);
}


/* Font sizes */
.fs-13 {
    font-size: 13px;
}

.dcWallet-topRightMenu .dropdown-move .dropdown-menu {
    backdrop-filter: blur(5px);
    background-color: #281c40;
    border: 1px solid #6d1fd6;
}

.dcWallet-topRightMenu .dropdown-move .dropdown-menu .dropdown-item span {
    font-size: 14px;
    padding-left: 5px;
    position: relative;
    top: 0px;
    color: #eadeffcc;
}

.dcWallet-topRightMenu .dropdown-move .dropdown-menu .dropdown-item {
    color: #fff;
    transition: all .2s ease-in-out;
}

.dcWallet-topRightMenu .dropdown-move .dropdown-menu .dropdown-item:hover {
    background-color: #ffffff0f;
}

@media (max-width: 768px) {
    .modal-full {
        height: -webkit-fill-available;
    }

    .modal-full .modal-content {
        height: 100%;
    }
}

.modal-title-new {
    text-align: center;
    width: 100%;
    color: rgb(142, 33, 255)!important;
}

.exportKeysModalColor {
    background-color: #25183d;
    backdrop-filter: blur(5px);
    color: #fff;
    max-height: 100%;
}

.exportKeysModalColor h3 {
    color: #d5adff !important;
}

.dcWallet-privateKeyDiv {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dcWallet-privateKeyDiv img {
    width: 110px;
}

.dcWallet-privateKeyDiv code {
    background-color: #00000033;
    padding: 10px 15px;
    border-radius: 12px;
    width: 85%;
    text-align: center;
    transition: all .2s ease-in-out;
    word-break: break-all;
}

.dcWallet-privateKeyDiv .blurred {
    color: transparent;
    text-shadow: 0 0 8px #e83e8c;
}

.dcWallet-privateKeyDiv h3 {
    margin-bottom: 10px;
}

.dcWallet-privateKeyDiv .span1 {
    font-size: 15px;
    color: #b9b9b9;
    font-weight: bold;
    display: block;
    margin-bottom: 2px;
}

.dcWallet-privateKeyDiv .span2 {
    font-size: 15px;
    display: block;
    margin-bottom: 30px;
    background: -webkit-linear-gradient(0deg, #8b4dfa, #F51E72);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.dcWallet-topLeftMenu {
    font-size: 18px;
    width: 27px;
    transition: all .2s ease-in-out;
}

.icoCol,
.dcWallet-topLeftMenu .topCol,
.dcWallet-topRightMenu .topCol {
    color: #9621FF;
}

.iconBtn svg {
    height: 18px!important;
}

.dashboardActionIcon svg {
    height:25px;
    fill:#9621FF;
    margin-bottom: 6px;
}

.giftIcons svg {
    height:16px;
    fill:#7A7387;
    position:relative;
    top:-4px;
}

.giftIconsClosed svg {
    height:16px;
    fill:#9621FF;
    position:relative;
    top:-2px;
}

.textboxTransparency {
    background-color: rgba(255, 255, 255, 15%);
    color: #fff;
    transition: all .2s ease-in-out;
}

.textboxTransparency::placeholder {
    color: rgb(211, 211, 211);
}

input {
    outline-color: rgb(180, 180, 180);
}

.dcWallet-btn-left {
    background-color: #ffffff29;
    width: 100px;
    height: 43px;
    margin-top: 17px;
    padding-top: 9px;
    border-radius: 24px;
    border-top-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-image: linear-gradient(183deg, #7B1FD8, #6D1ED8);
    cursor: pointer;
}

.dcWallet-btn-right {
    background-color: #ffffff29;
    width: 100px;
    height: 43px;
    margin-top: 17px;
    padding-top: 9px;
    border-radius: 24px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-image: linear-gradient(183deg, #7B1FD8, #6D1ED8);
    cursor: pointer;
}


.blackBack {
    transition: all 0.15s ease-in-out;
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.blackBackHide {
    opacity: 0 !important;
}

.abcd {
    padding: 14px 16px;
    background-color: rgba(255, 255, 255, 12%);
    border-radius: 10px;
    backdrop-filter: blur(4px);
}

.btn-group-input {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-text {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: 13px;
    background-color: #fff;
    border: 0px;
}

.input-group-toggle {
    cursor: pointer;
    background-color: rgb(127, 32, 255);
    border: 2px solid #AF9CC6;
    color: rgb(233, 222, 255);
    padding: 10px 8px !important;
    display: inline-block;
    width: 10%;
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.cur-pointer {
    cursor: pointer;
}

.stakingTx {
    color: #fff;
    font-size: 14px;
}

.stakingTx thead tr th {
    padding: 9px 20px 9px;
    font-family: Montserrat;
    letter-spacing: .5px;
    color: #E9DEFF;
    background: rgba(100, 16, 199, .3);
    border: 0px;
    font-weight: 500;
}

.stakingTx thead {
    border: 1px rgba(146, 33, 255, .3) solid;
}

.stakingTx tbody tr td {
    padding: 9px 20px 9px;
    border-bottom: 1px solid #ffffff1f;
    border-top: 0px;
}

.stakingTx tbody tr {
    transition: all .125s ease-in-out;
}

.stakingTx tbody tr:hover {
    background-color: #00000017;
}

.txcode code {
    font-weight: 700;
}

.badge-purple {
    background-color: #6410C7 !important;
    padding: 4px 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.badge-danger {
    background-color: #df3b3b !important;
    padding: 4px 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.pr-10px {
    padding-right: 10px !important;
}

.table-mobile-scroll {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

@media (max-width: 767.98px) {
    .tx1 {
        width: 64px;
    }

    .tx2 {
        width: 900px;
    }

    .tx3 {
        width: 69px;
    }

    .tx4 {
        width: 40px;
    }
}

@media (min-width: 767.98px) {
    .tx1 {
        width: 190px;
    }

    .tx2 {
        width: 900px;
    }

    .txcode code {
        width: max-content;
    }

    .tx3 {
        width: 900px;
    }

    .tx4 {
        width: 300px;
    }
}

.closeCross {
    color: #fff;
    font-size: 20px;
}

.dropdown {
    z-index: 10;
}

.scrollTable {
    display: flex;
    flex-flow: column;
    max-height: 500px;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #1B102E;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #9221FF;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #8916f5;
}

@media (min-width: 768px) {
    .max-w-600 {
        max-width: 600px;
    }
}

.table-promo {
    transition: all 0.5s ease-in-out;
    max-height: 0px;
}

select.form-control {
    color: #6410c7;
    background-color: #e9deff;
    border: 2px solid #AF9CC6;
    font-weight: 500;
    border-radius: 8px;
    padding: 11px 11px;
    height: auto;
    font-size: 15px;
}

select.form-control:focus {
    color: #6410c7;
    background-color: #e9deff;
    border: 2px solid #AF9CC6;
    font-weight: 500;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 22%);
}

select.form-control option {
    background-color: #e9deff;
    color:#9221FF;
}

.settingsMenu {
    border-radius: 9px;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display:flex;
    height: 53px;
}

.settingsMenu li {
    margin-right: 9px;
    margin-left: 9px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    margin-top: 1px;
    padding-left: 44px;
    padding-right: 44px;

    padding-top: 9px;
    padding-bottom: 9px;
    height: 43px;
    border:1px solid #41117B;
    position: relative;
    top: 10px;

    transition: all 0.125s ease-in-out;
    background: linear-gradient(180deg, rgba(124,31,216,1) 0%, rgba(116,31,216,1) 39%, rgba(109,30,216,1) 100%);
}

.settingsMenu li:hover {
    background: linear-gradient(180deg, rgba(124,31,216,1) 0%, rgba(116,31,216,1) 39%, rgba(109,30,216,1) 100%);
    background-color: #422E59;
    cursor: pointer;
    color: #fff !important;
}

.settingsMenu .active {
    background:none;
    background-color: #281D3D;
    margin-bottom: -1px;
    border:1px solid #41117B;
    border-bottom:0px;
    color:#9021FF;
    top: 0px;
    
    height: auto!important;
    padding-top: 14px!important;
    padding-bottom: 14px!important;
    font-weight: 600;
}

.redeemMenu .active {
    background-color: #25183D;
}

.settingsMenu .active:hover {
    background:none;
    background-color: #281D3D;
    margin-bottom: -1px;
    border:1px solid #41117B;
    border-bottom:0px;
    color:#9021FF!important;
    top: 0px;
    
    height: auto!important;
    padding-top: 14px!important;
    padding-bottom: 14px!important;
    
}

.settingsContent {
    background-color: #281D3D;
    border-radius: 9px;
    padding-top: 13px;
    padding-bottom: 20px;
    padding-left: 17px;
    padding-right: 17px;
    font-size: 15px;
    border:1px solid #41117B;
}

.settingsContent .header-title {
    font-size: 17px;
    font-weight: 600;
    display: block;
    margin-bottom: 12px;
}

.settingsContent hr {
    background-color: #640FC7;
    margin-top: 11px;
}

.custom-switch {
    padding-left: 55px;
}

.custom-switch .custom-control-label {
    padding-top: 3px;
}

.custom-switch .custom-control-label::before {
    width: 45px;
    border-radius: 100px;
    left: -55px;
}

.custom-switch .custom-control-label::before {
    height: 22px;
}

.custom-switch .custom-control-label::after {
    width: calc(22px - 4px);
    height: calc(22px - 4px);
    left: calc(-55px + 2px);
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    -webkit-transform: translateX(22px);
    transform: translateX(22px);
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #9169b5;
    background-color: #9169b5;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #9169b575;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #9169b575;
}

#foot {
    z-index: 200;
}


/* Flipdown styling (flipdown.js library) */
.flipdown.flipdown__theme-dark {
    font-family: sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

.flipdown.flipdown__theme-dark .rotor-group-heading:before {
    font-size: 13px;
    color: #cac9d2;
}

.flipdown.flipdown__theme-dark .rotor-group:nth-child(n+2):nth-child(-n+3):before,
.flipdown.flipdown__theme-dark .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    background-color: #6410C7;
}

.flipdown.flipdown__theme-dark .rotor,
.flipdown.flipdown__theme-dark .rotor-top,
.flipdown.flipdown__theme-dark .rotor-leaf-front {
    color: #FFFFFF;
    background-color: #431180;
}

.flipdown.flipdown__theme-dark .rotor-bottom,
.flipdown.flipdown__theme-dark .rotor-leaf-rear {
    color: #EFEFEF;
    background-color: #6410c7;
}

.flipdown.flipdown__theme-dark .rotor:after {
    border-top: solid 2px #1d142e;
}

.flipdown {
    overflow: visible;
    margin-top: 7px;
}

.flipdown .rotor-group {
    position: relative;
    float: left;
    padding-right: 30px;
}

.flipdown .rotor-group:last-child {
    padding-right: 0;
}

.flipdown .rotor-group-heading:before {
    display: block;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.flipdown .rotor-group:nth-child(1) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(2) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(3) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(4) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 85px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    content: '';
    position: absolute;
    bottom: 35px;
    left: 85px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.flipdown .rotor {
    position: relative;
    float: left;
    width: 35px;
    height: 60px;
    margin: 0px 5px 0px 0px;
    border-radius: 4px;
    font-size: 48px;
    text-align: center;
    perspective: 200px;
}

.flipdown .rotor:last-child {
    margin-right: 0;
}

.flipdown .rotor-top,
.flipdown .rotor-bottom {
    overflow: hidden;
    position: absolute;
    width: 35px;
    height: 30px;
}

.flipdown .rotor-leaf {
    z-index: 1;
    position: absolute;
    width: 35px;
    height: 60px;
    transform-style: preserve-3d;
    transition: transform 0s;
}

.flipdown .rotor-leaf.flipped {
    transform: rotateX(-180deg);
    transition: all 0.5s ease-in-out;
}

.flipdown .rotor-leaf-front,
.flipdown .rotor-leaf-rear {
    overflow: hidden;
    position: absolute;
    width: 35px;
    height: 30px;
    margin: 0;
    transform: rotateX(0deg);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.flipdown .rotor-leaf-front {
    line-height: 60px;
    border-radius: 4px 4px 0px 0px;
}

.flipdown .rotor-leaf-rear {
    line-height: 0px;
    border-radius: 0px 0px 4px 4px;
    transform: rotateX(-180deg);
}

.flipdown .rotor-top {
    line-height: 60px;
    border-radius: 4px 4px 0px 0px;
}

.flipdown .rotor-bottom {
    bottom: 0;
    line-height: 0px;
    border-radius: 0px 0px 4px 4px;
}

.flipdown .rotor:after {
    content: '';
    z-index: 2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 35px;
    height: 30px;
    border-radius: 0px 0px 4px 4px;
}

@media (max-width: 550px) {
    .flipdown {
        width: 316px;
        height: 70px;
    }

    .flipdown .rotor {
        font-size: 2.2rem;
        margin-right: 3px;
    }

    .flipdown .rotor,
    .flipdown .rotor-leaf,
    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
        width: 30px;
    }

    .flipdown .rotor-group {
        padding-right: 20px;
    }

    .flipdown .rotor-group:last-child {
        padding-right: 0px;
    }

    .flipdown .rotor-group-heading:before {
        font-size: 0.8rem;
        height: 20px;
        line-height: 20px;
    }

    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before,
    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        left: 69px;
    }

    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before {
        bottom: 13px;
        height: 8px;
        width: 8px;
    }

    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        bottom: 29px;
        height: 8px;
        width: 8px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-top {
        line-height: 50px;
    }

    .flipdown .rotor-leaf,
    .flipdown .rotor {
        height: 50px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
        height: 25px;
    }
}

.governLink:hover {
    text-decoration: underline !important;
    cursor: pointer;
}

.governInstallments {
    color: #d1d1d1;
    line-height: 17px;
    display: block;
    font-size: 12px;
}

.governMarked {
    color: #7C1DEA;
    font-weight: 700;
}

.governValues {
    line-height: 18px;
    display: block;
    margin-bottom: 8px;
}

.governFiatSize {
    font-size: 12px;
}

.governLinkIco {
    font-size: 11px;
    margin-left: 3px;
    color: #bdbdbd;
}

.governBudgetCard {
    font-weight: 700;
    font-size: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.governPayoutTime {
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.governTable thead tr td {
    font-size: 13px;
    border-top: 0px;
    background-color: #371665;
}

.governTable thead tr td {
    padding-top:10px;
    padding-bottom:10px;
}

.governTable .btlr-7p {
    border-top-left-radius: 7px;
}

.governTable .btrr-7p {
    border-top-right-radius: 7px;
}

.governTable .bblr-7p {
    border-bottom-left-radius: 7px;
}

.governTable .bbrr-7p {
    border-bottom-right-radius: 7px;
}

.governTable tbody tr td {
    border-top: 1px solid #a7a7a72b;
}

.governTable tbody tr:nth-child(4n-1) {
    background-color: #0000000f !important;
}

.governTable tbody tr:nth-child(even) {
    background-color: #0000000f !important;
}

.governTable tbody tr {
    transition: all 0.125s ease-in-out;
}

.governTable tbody tr:hover {
    background-color: #ffffff0a !important;
}

.votesYes {
    color: #6fc500;
}

.votesOverAllocated {
    color:#dfb04b;
}

.votesNo {
    color: #ff1d1d;
}

.governTable .votesBg {
    background-color: #1a122d;
    padding: 7px 7px 3px 7px;
    border-radius: 5px;
    font-weight: 100!important;
}

.governTable .governStatusCol {
    background-color: #00000017;
}

.governTable .governStatusCol .governArrow {
    position: absolute;
    left: -11px;
    margin-top: -49px;
    background-color: #00000045;
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.governTable .governStatusCol .governArrow i {
    position: relative;
    top: 1px;
}

.governMobDot {
    display: inline-block;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    margin-right: 6px;
    margin-left: 17px;
    background-color: #8d60e6;
}

.governHr {
    border-top: 1px solid #a7a7a72b;
}

.governAdd {
    position: absolute;
    right: 0px;
    height: 31px;
    width: 31px;
    padding: 7px 10px;
    margin-top: 6px;
}

.fs-13 {
    font-size: 13px;
}

.fw-600 {
    font-weight: 600;
}

@media (min-width: 992px) {
    .for-mobile {
        display: none !important;
    }

    .for-desktopTime {
        position: relative;
        top: -15px;
    }
}

@media (max-width: 992px) {
    .for-desktop {
        display: none !important;
    }

    .governPayoutTime {
        padding-top: 26px;
    }
}

.notifyButtonFirst {
    border-bottom-right-radius:0px!important;
    margin-right:1px!important;
    padding: 0px 21px;
}

.notifyButtonSecond {
    border-bottom-left-radius:0px!important;
    margin-left:1px!important;
    width:100%;
    text-transform: uppercase;
}

.btn-notification-close {
    color: #D5C9EC;
    border: 2px solid #8529ED;
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -1px;
    margin-bottom: -1px;
    margin-right: -1px;
    background: rgb(30,20,49);
    background: linear-gradient(0deg, rgba(30,20,49,1) 0%, rgba(44,16,79,1) 100%);
    font-weight: 500;
}

.btn-notification-close:hover {
    border: 2px solid #8529ED;
    color: #c7bbdd;
}

.btn-notification-action {
    color: #D5C9EC;
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -1px;
    margin-bottom: -1px;
    margin-right: -1px;
    background: rgb(30,20,49);
    background: linear-gradient(0deg, #741EEA 0%, #9231EE 100%);
    font-weight: 500;
}

.btn-notification-action:hover {
    color: #c7bbdd;
}

.notifyWrapper .notifyBadgeCount {
    position: absolute;
    right: 0px;
    border: 2px solid #9A21FF;
    background-color: #431180;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}

.notifyWrapper {
    opacity: 1;
    z-index: 999999;
    background-color: #260a47;
    border-radius: 11px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid #51199f;
    margin-bottom: 15px;
    opacity: 0;
    transition: all 0.250s ease-in-out;
}

.notifyWrapper .notifyIcon {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    height: 45px;
    margin-top: 17px;
    margin-left: -5px;
    width: 45px;
    justify-content: center;
    align-items: center;
}

.notifyWrapper .notify-warning {
    background: rgb(138,0,29);
    background: linear-gradient(0deg, rgba(138,0,29,1) 0%, rgba(181,0,0,1) 100%);
    border: 1px solid #FF1C50;
}

.notifyWrapper .notify-info {
    background: rgb(47,18,83);
    background: linear-gradient(341deg, rgba(47,18,83,1) 0%, rgba(123,101,157,1) 100%);
    border: 1px solid #906DB1;
}

.notifyWrapper .notify-success {
    background: rgb(46,82,0);
    background: linear-gradient(341deg, rgba(46,82,0,1) 0%, rgba(95,168,0,1) 100%);
    border: 1px solid #69BB00;
}

.notifyWrapper .notifyText {
    padding-left: 14px;
    padding-right: 40px;
    padding-top: 19px;
    padding-bottom: 19px;
    color: #8c7aa8;
}

.notifyWrapper .notifyText b {
    color:#DCD1F3;
}

.sliderStyle .arrow {
    display: none;
}

.tooltip.sliderStyle {
    top: -10px !important;
}

.sliderStyle .tooltip-inner {
    width: 30px;
    height: 30px;
    background-color: #ffffffb3;
    border-radius: 25%;
    color: #000;
    font-weight: 500;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 7.5px;
    border-color: rgba(255, 255, 255, 0%);
    background: rgba(255, 255, 255, 22%);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #b988e1;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #b988e1;
    cursor: pointer;
}

.sliderContainer {
    position: relative;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

.slider {
    width: 100%;
}

.sliderDisplay {
    /*position: absolute;
    top: 50%;
    margin-left: 0px;
    transform: translate(-50%, -50%);
    margin-top: -6px;
    z-index: 40;
    pointer-events: none;*/

    /*text-align: center;*/
    /*width: 2em;*/
}

@media (min-width:768px) {
    .sliderDisplay {
        display: none;
    }
}

@media (min-width: 768px) {
    .max-w-450 {
        max-width: 450px;
    }
}

.contactsList .contactItem {
    transition: all .25s ease-in-out;
}

.pIconBin svg {
    height:19px;
    width:19px;
    fill:#9221FF;
}

.contactsList .addContact .contactName input,
.contactsList .addContact .contactAddr input,
.contactsList .addContactBtn,
.contactsList .qrContactBtn {
    color: #ffffff;
    background-color: #f2f2f233;
    border: 1px solid #f2f2f224;
}

.contactsList .addContact .contactName input::placeholder,
.contactsList .addContact .contactAddr input::placeholder {
    color: #dddddd;
}

.contactsList .addContact .contactName input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.contactsList .addContact .contactAddr input {
    border-left: none;
    border-right: none;
    border-radius: 0px;
}

.contactsList .addContactBtn {
    cursor: pointer;
    border-radius: 0px;
    padding: 0px 10px;
    font-weight: bold;
    width: fit-content;
    height: 43px;
    display: flex;border-top-left-radius: 0px;
    align-items: center;
    transition: all .125s ease-in-out;
    border-right: none;
}

.contactsList .qrContactBtn {
    cursor: pointer;
    border-radius: 7px;
    padding: 0px 10px;
    font-weight: bold;
    width: fit-content;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 43px;
    display: flex;
    align-items: center;
    transition: all .125s ease-in-out;
}

.contactsList .addContactBtn:hover,
.contactsList .qrContactBtn:hover {
    background-color: #f2f2f24d;
}

.progress .progress-bar {
    background-image: none;
    background-color: #7000ab;
    width: 50% !important;
}

.nav-centered {
    display: flex;
    width: calc(100% - 80px);
    justify-content: center;
    margin-right: 80px;
}

.modal.show {
    display: flex!important;
    justify-content: center;
}

.modalMain {
    background-color: #221239;
}

.modal-dialog {
    padding-top: 100px;
    width: 380px;
}

.modal-content {
    border-radius: 10px;
    border: 1px solid #42117e;
}

.modal-title {
    color: #d5adff  !important;
    font-size: 25px;
    margin-top: 22px;
}

.modalContents {
    margin-top: -14px;
}

.modalContents .topText {
    padding: 0px 39px;
    color: #AF9CC6;
    font-size: 16px;
    font-weight: 500;
    display: block;
}

.textGradientKeys {
    background: -webkit-linear-gradient(left, #9B21FB , #FC1DA0);
    background: -o-linear-gradient(right, #9B21FB, #FC1DA0);
    background: -moz-linear-gradient(right, #9B21FB, #FC1DA0);
    background: linear-gradient(to right, #9B21FB , #FC1DA0); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    font-weight: 600;
}

.rewardsBadge {
    background-color: #5811AB;
    border-radius: 8px;
    margin-left: 18px;
    padding: 4px 13px;
    position: relative;
    top: -2px;
}

.plus-icon,
.change-pwd-icon,
.del-wallet-icon {
    margin-right: 5px;
}



.plus-icon svg path,
.change-pwd-icon svg path,
.del-wallet-icon svg path {
    fill: #fff;
}

.gift-icon svg path {
    fill:#AF9CC6;
    margin-right: 4px;
}

.plus-icon svg {
    height: 15px;
}

.gift-icon svg {
    height: 15px;
    position: relative;
    top: -1px;
}

.change-pwd-icon svg,
.del-wallet-icon svg {
    height: 15px;
    position: relative;
    top: -2px;
}

.masternodeModalDialog {
    width: 800px;
}

textarea:focus-visible {
    outline:none;
}

textarea::placeholder {
    color:#A97DE8;
}

.masternodeModalDialog2 .modal-footer {
    width: 300px;
    padding-left: calc(50%);
}

.masternodeModalDialog2 .modal-footer button {
    margin-left:15px;
}

.masternodeModalDialog2 .modal-footer .pivx-button-outline {
    margin-right:15px;
}

@media (min-width: 576px) {
    .masternodeModalDialog {
        max-width: 800px;
    }
}

.transferBody {
    font: 14px 'Montserrat Regular';
    color: #AF9CC6;
    font-weight: 500;
}

.transferBody label,
.transferBody label {
    margin-left:5px;
    margin-bottom: 10px;
}

.tranferModal .input-group-text {
    background-color: #7f20ff;
    color: #E9DEFF;
    border: 2px solid #af9cc6;
    border-left: 0px;
}

.tranferModal .input-group-text:nth-child(2) {
    border: 2px solid #af9cc6;
}

.masternodeBadges {
    color: #000;
    font-weight: 600;
    padding: 5px 11px 5px 11px;
    border-radius: 6px;
}

.enabledBadge {
    background-color:#5FA800;
}

.preEnabledBadge {
    background-color:#A49DB2;
}

.missingBadge {
    background-color:#FF1D1D;
}

.masternodeTable tbody tr td {
    padding-top: 25px;
    padding-bottom: 25px;
}

.cameraIcon svg {
    height:22px;
    width:22px;
    fill:#e9deff;
}

.masternodeTable tbody tr td .wallet-code {
    width:fit-content!important;
    font-weight:600;
}

.masternodeTable tbody tr td .mnLastSeen {
    color:#76678D;
    font-weight:500;
}

.pColor svg {
    fill:#6410c7;
}
.wallet-code-new {
    background-color: #e9deff;
    color: #6410c7;
    border-radius: 7px;
    border: 1px solid #af9cc6;
    padding: 7px 10px;
    display: block;
    /* Allow addresses to nicely cut-off to encourage Clipboard usage */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mnTopConfigured {
    display: flex;
    justify-content: center;
    margin-top: 33px;
    margin-bottom: 35px;
    color:#E9DEFF;
}

.modal {
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%!important;
}

.shadowInnerCard {
    -webkit-box-shadow: 5px 5px 14px -5px rgba(22, 22, 22, 1);
    -moz-box-shadow: 5px 5px 14px -5px rgba(22, 22, 22, 1);
    box-shadow: 5px 5px 14px -5px rgba(22, 22, 22, 1);
}

@media (max-width:992px) {
    .navBarActions {
        flex-direction: row;
        height: 45px;
        display: flex;
        align-items: center;
    }

    .navBarActions .nav-item .nav-link {
        display:flex;
        justify-content: center;
        width:40px;
    }
}

.goToWalletIco svg {
    height: 18px;
    position: relative;
    top: 2px;
    right: 6px;
}

.tooltip {
    z-index: 5000;
}

.gradientBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.250s ease-in-out;
}

.navbar::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1A122D, #150927)!important;
    opacity: 0;
    z-index:-10;
    transition: opacity 0.125s ease-in-out;
}

.navbar.active::after {
    opacity: 1;
}

.navbar-toggler {
    border:0px;
}

.navbar-toggler:hover span {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.7%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e')
}

.sendHeaderoText {
    color:#d5adff!important;
}

@media (max-width: 992px) {
    .govNoBtnMob span {
        width:62px;
        text-align: center;
    }

    .govYesBtnMob {
        width:68px;
        margin-left: 0px;
    }

    #foot {
        position: relative!important;
    }
}

.modal-body {
    overflow: auto;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spinningLoading {
    animation-name: spin;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

.loadingDots {
    display: flex;
    justify-content: center;
    margin-bottom:25px;
}

.loadingDots span {
    height:16px;
    width:16px;
    background-color:#9621FF;
    border-radius:100px;
    animation: fade 1.5s infinite ease-in-out;
}

.loadingDots span:nth-child(2) {
    margin-left:15px;
    margin-right:15px;
}

/* Delay for each dot */
.loadingDots span:nth-child(1) {
    animation-delay: 0s;
}

.loadingDots span:nth-child(2) {
    animation-delay: 0.3s;
}

.loadingDots span:nth-child(3) {
    animation-delay: 0.6s;
}

/* Animation keyframes */
@keyframes fade {
    0%, 100% {
        opacity: 25%;
    }
    50% {
        opacity: 1;
    }
}

.footer-icons i {
    color:#7F20FF;
}

.footer-icons a {
    margin-left: 3px;
    margin-right: 3px;
}

.footer-icons a:first-child {
    margin-left:0px;
}

.footer-icons a:last-child {
    margin-right:0px;
}

@media (min-width:992px) {
    .w-50-mob {
        width:50%;
    }
    .footFlexMob {
        display:flex;
    }
    .textCenterMob {
        text-align: center;
    }
    .showMenuMobile {
        display:none;
    }
}

@media (max-width:992px) {
    .footer-icons i {
        font-size: 18px;
    }
}

@media (max-width:768px) {
    .mobileVote {
        display:flex;
        align-items: center;
    }

    .mobileVote .pivx-button-outline {
        margin-right:7px;
    }
}
